 /* .p-progress-circle {
animation: ui-progress-spinner-color 6s ease-in-out infinite;
}
@keyframes ui-progress-spinner-color {
    100%,
    0% {
        stroke: #e9dfdf;
    }
    40% {
        stroke: #FFF;
    }
    66% {
        stroke: #FFF;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}
   */
   .screen-container{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .screen-left-container{
    flex: 1;
    background: var(--unnamed-color-0b141f) 0% 0% no-repeat padding-box;
    background: #0B141F 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .logo-image{
    width: 100%;
    max-width: 26rem;
    margin-top: 4rem;
  }

  .link-banner{
    display: flex;
    justify-content: flex-end;
    width: 100%;

  }


  .logo-image-banner{
    width: 100%;
    margin-right: -15px;
    height: 40rem;
    object-fit: cover;
  }

  .container-title {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }

  .login-title{
    margin: 0 auto;
    text-align: center;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 42px/57px var(--unnamed-font-family-open-sans);
    text-align: center;
    font: normal normal 600 40px/55px Open Sans;
    letter-spacing: 0.08px;
    color: #FFFFFF;
    opacity: 1;
    font-size: clamp(1.7rem, 1vw, 3rem);
  }


  .line{
    width: 50%;
    margin-left: 25%;
    margin-top: 0.5rem;
    height: 0px;
    border: 2px solid #FFC800;
    opacity: 1;
  }


  .user-text{
    width: 50%;
    margin-left: 25%;
    margin-top: 1.7rem;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0-7);
    text-align: left;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.6;

  }

  .role-text{
    width: 50%;
    margin-left: 25%;
    margin-top: 5%;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0-7);
    text-align: left;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.6;

  }


  .user-box{
    width: 50%;
    background: #FFFFFF0F 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF33;
    border-radius: 5px;
    opacity: 1;
  }

  .codigo-text{
    width: 50%;
    margin-left: 25%;
    margin-top: 1rem;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0-7);
    text-align: left;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.6;

  }

  .codigo-box{
    width: 50%;
    background: #FFFFFF0F 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF33;
    border-radius: 5px;
    opacity: 1;
  }

  .password-text{
    width: 50%;
    margin-left: 25%;
    margin-top: 5%;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0-7);
    text-align: left;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.6;
    }

  .password-box{
      width: 50%;
      background: #FFFFFF0F 0% 0% no-repeat padding-box;
      border: 1px solid #FFFFFF33;
      border-radius: 5px;
      opacity: 1;
  }

  .forgotten {
    margin-top: 2%;
    margin-left: 55%;
    width: 25%;
    text-decoration: var(--unnamed-decoration-underline);
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0-7);
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0.7px;
    color: #FFFFFF;
    opacity: 0.6;
  }

  .login-button{
    width: 40%;
    background:  0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  .screen-right-container{
    flex: 1;
    background: #080F17 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .info-title{
    display: flex;
    width: 80%;
    margin-top: 10%;
    margin-left: 7.5%;
    font: var(--unnamed-font-style-normal) normal bold 40px/73px var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal bold 45px/73px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .info-subtitle{
    z-index: 10;
    position: relative;
    display: flex;
    width: 80%;
    margin-bottom: 5%;
    margin-left: 0;
    text-align: left;
    letter-spacing: 0.48px;
    color: #CED0D2;
    opacity: 1;
  }

  .info-subtitle p {
    margin: 0 auto;
  }

  .list-title{
    display: flex;
    margin-left: 7.5%;
    margin-bottom: 2%;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 22px/var(--unnamed-line-spacing-24) var(--unnamed-font-family-open-sans);
    text-align: left;
    font: normal normal 600 20px/24px Open Sans;
    letter-spacing: 0.33px;
    color: #FFFFFF;
    opacity: 1;
  }

  .arrow-container{

    flex-direction: row;
    justify-content: flex-start;
  }

  .arrow-image {

    opacity: 1;
  }

  .list-content{
    margin-left: 0;
    margin-bottom: 0;
    z-index: 10;
    position: relative;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-open-sans);
    text-align: left;
    font: normal normal 600 14px/24px Open Sans;
    letter-spacing: 0.24px;
    color: #CED0D2;
    opacity: 1;
  }

  .list-content li {
    margin-top: 0.5rem;
  }

  .map-image {
    width: 35%;
    background: 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;

  }

  .tracking-system-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: 2.19rem;
  }

  .tracking-system-container h1 {
    font: normal normal 600 40px/55px Open Sans;
    letter-spacing: 0.08px;
    color: #FFFFFF;
    opacity: 1;
    font-size: clamp(1.7rem, 1vw, 3rem);
    margin: 0 auto;
    margin-top: 2rem;
  }

  .advantages-container {
width: 100%;
display: flex;
align-items: flex-end;
justify-content: flex-end;
  }

  .advantages-container h2 {
    font: normal normal 600 40px/55px Open Sans;
    letter-spacing: 0.08px;
    color: #FFFFFF;
    opacity: 1;
    font-size: clamp(1.8rem, 1vw, 2rem);
    margin: 0 auto;
  }
