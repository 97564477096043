.btn-print{
  display: block;
  background: #ffda53 0% 0% no-repeat padding-box;
  border: 2px solid #ffda53;
  color: #0b141f;
  border-radius: 5px;
  font-size: 16px;
  height: 50px;
  margin: 0px 10px;
  padding: 0 0.5rem;
}

@media print {
     .btn-print{
        display: none;
      }
}
