.progresso-ponto {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color:white;
  z-index: 1;
  position: relative;
}

.progress-name-container {
  max-width: 90px;
  width: 90px;
  position: absolute;
  left: -26px;
  bottom: -20px;
  margin-top: 4px;
}

.progress-name-container > span {
  font-size: 12px;
  width: 100%;
  display: block;
  word-break: break-all;  
  color: none;
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

