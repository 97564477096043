.inputGrid {
    border: none;
    outline: none;
    /* margin: 5px; */
    /* margin-right: 20px; */
    width: 90%;
    background-color: #fff;
    border-radius: 5px;
}

.teste:hover input {
    background: #ECECEC;
}