@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

body{
  background-color: --unnamed-color-a4a4a4;
}
:root {
  /* Colors: */
  --unnamed-color-0b141f: #0b141f;
  --unnamed-color-a4a4a4: #a4a4a4;
  --unnamed-color-333333: #333333;
  --unnamed-color-737373: #737373;
  --unnamed-color-5ec4ff: #5ec4ff;

  /* Font/text values */
  --unnamed-font-family-open-sans: Open Sans;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-600: 600px;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-28: 28px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-03: 0.03px;
  --unnamed-character-spacing-0-28: 0.28px;
  --unnamed-character-spacing-0-7: 0.7px;
  --unnamed-character-spacing-0-04: 0.04px;
  --unnamed-line-spacing-19: 19px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-38: 38px;
  --unnamed-decoration-underline: underline;
}
.loading-spinner-veiculo {
  align-self: center;
  justify-self: center;
  flex: 1;
}

.pi-plus {
  -webkit-text-stroke: 1.25px;
}


.preenchimento-dos-inputs {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-333333);
}
.abas-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-a4a4a4);
}

.botão {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-04);
  color: var(--unnamed-color-333333);
}
.links {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-04);
  color: var(--unnamed-color-3d7ab8);
  text-decoration: var(--unnamed-decoration-underline);
}

.titulo {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-28) / var(--unnamed-line-spacing-38)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-333333);
  text-align: left;
  font: normal normal 600 28px/38px Open Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.btn-create {
  width: 40px;
  height: 40px;
  background: #ffda53 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.search-create {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.titulo-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.subtitulo {
  font-size: 14px;
  margin-left: 80px;
  margin-top: 5px;
  color: #a4a4a4;
}

tbody td {
  color: #333333;
  font-family: 'Open Sans';
  border-bottom: 2px solid #dedede;
}

thead {
  border-top: 2px solid transparent;
}

thead th {
  color: #a4a4a4;
  font-size: 14px;
  font-family: 'Open Sans';
}

table {
  border: 1px;
}

.card {
  padding: 15px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 0;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 10px;
  min-height: 90vh;
  flex: 1;
}

.card .row{
align-items: baseline;

}

.card .breadcrumb{
  background:none;
  font:#DEDEDE
  
}

.card .breadcrumb a{ 
  color: #ccc;
  
}

.card .breadcrumb > li + li:before {
  color: #ccc;
  content: "> ";
  padding: 0 1px 0 1px;
}

.container-busca{
  display:flex;
  align-items: baseline;
  
}

.borderless th {
  border: none !important;
}

.icons{
  display: flex;
  flex-direction: row;
}
