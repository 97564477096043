    /*Elementos Gerais, sem classe ou ID*/
    hr{
        border: 1px solid #DEDEDE;
    }

    /*Estilos trazidos do layout confeccionado pelo Bruno*/
    /*Fim dos Estilos trazidos do layout confeccionado pelo Bruno*/
    :root {

        /* Colors: */
        --unnamed-color-0b141f: #0B141F;
        --unnamed-color-a4a4a4: #A4A4A4;
        --unnamed-color-333333: #333333;
        --unnamed-color-737373: #737373;
        --unnamed-color-5ec4ff: #5EC4FF;
        
        /* Font/text values */
        --unnamed-font-family-open-sans: Open Sans;
        --unnamed-font-style-normal: normal;
        --unnamed-font-weight-600: 600px;
        --unnamed-font-weight-normal: normal;
        --unnamed-font-size-14: 14px;
        --unnamed-font-size-16: 16px;
        --unnamed-font-size-18: 18px;
        --unnamed-font-size-28: 28px;
        --unnamed-character-spacing-0: 0px;
        --unnamed-character-spacing-0-03: 0.03px;
        --unnamed-character-spacing-0-28: 0.28px;
        --unnamed-character-spacing-0-7: 0.7px;
        --unnamed-character-spacing-0-04: 0.04px;
        --unnamed-line-spacing-19: 19px;
        --unnamed-line-spacing-22: 22px;
        --unnamed-line-spacing-24: 24px;
        --unnamed-line-spacing-38: 38px;
        --unnamed-decoration-underline: underline;
    }

    .navbar{
        width: 406px;
        height: 38px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-333333);
        text-align: left;
        font: normal normal 600 28px/38px Open Sans;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        font-size:1.5rem;
    }

    .navbar.navbar-expand.navbar-expand.navbar-light{
        padding: 0.5rem 0;
    }

        .navbar.navbar-expand.navbar-expand.navbar-light .navbar-brand{
            font-size:1.5rem;
            padding: 0.5rem 0;
        }

        .nav-link-item-header{
            width: 300px;
            height: 19px;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
            letter-spacing: var(--unnamed-character-spacing-0-03);
            color: var(--unnamed-color-a4a4a4);
            text-align: left;
            font: normal normal normal 14px/19px Open Sans;
            letter-spacing: 0.03px;
            color: #A4A4A4;
            opacity: 1;
        }

    div.container-fluid{
        margin-top: 50px;
        margin-bottom: 50px;
        top: 50px;
        left: 106px;
        width: 100%;
        height: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DEDEDE;
        border-radius: 10px;
        opacity: 1;
    }

        .info-obrigatorio{
            color:#F33D3D;
            font-size:0.7rem;
            position: relative;
            top: 2.5rem;
        }

        span.txt-obrigatorio{
            color:#F33D3D;
        }



    .form-sm{
        margin-top:3.5rem;
    }

        .form-label{
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
            letter-spacing: var(--unnamed-character-spacing-0-7);
            text-align: left;
            font: normal normal 600 14px/19px Open Sans;
            letter-spacing: 0.7px;
            opacity: 1;
            color: var(--unnamed-color-737373);
            /*
            font-family: var(--unnamed-font-family-open-sans);
            font-style: var(--unnamed-font-style-normal);
            font-weight: var(--unnamed-font-weight-600);
            font-size: var(--unnamed-font-size-14);
            line-height: var(--unnamed-line-spacing-19);
            letter-spacing: var(--unnamed-character-spacing-0-7);
            */
            color: var(--unnamed-color-737373);
            text-transform: uppercase;
            font-size:0.7rem;
        }

        .form-sm > .form-row > .form-group.col > .form-control,
        .form-sm > .form-row > .form-group.col-md-3 > select.form-control,
        .form-sm > .justify-content-md-center.form-row > .col-md-2 > select.form-control,
        .form-sm > .form-row > .form-group.col-md-6 > input.form-control,
        .form-sm > .form-row > .form-group.col-md-6 > select.form-control,
        .form-sm > .justify-content-md-center.mt-4.form-row > .col-md-3 > input.form-control,
        .form-sm > .justify-content-md-center.mt-3.form-row > .col-md-3 > input.form-control{
            font-family: var(--unnamed-font-family-open-sans);
            font-style: var(--unnamed-font-style-normal);
            font-weight: var(--unnamed-font-weight-normal);
            font-size: var(--unnamed-font-size-14);
            line-height: var(--unnamed-line-spacing-19);
            letter-spacing: var(--unnamed-character-spacing-0-03);
            color: var(--unnamed-color-333333); 
            font-size:0.7rem;
        }

        .container-buttons{
            margin-top:1.5rem;
        }

            .form-sm > .container-buttons.form-row > .form-group.col-md-2 {
                text-align: right;
                margin-right: 2rem;
            }

            .form-sm > .container-buttons.form-row > .form-group.col-md-2 > .btn-cancelar.btn.btn-primary{
                width: 175px;
                height: 50px;
                border: 2px solid var(--unnamed-color-0b141f);
                border: 2px solid #0B141F;
                background: #FFF 0% 0% no-repeat padding-box;
                color: #0B141F;
                border-radius: 5px;
                opacity: 1;
                font-weight: 400;
            }

            .form-sm > .container-buttons.form-row > .form-group.col-md-2 > .btn-enviar{
                width: 183px;
                height: 50px;
                background: #FFDA53 0% 0% no-repeat padding-box;
                border: 2px solid #FFDA53;
                color: #0B141F;
                border-radius: 5px;
                opacity: 1;
                font-weight: 400;
            }

        .datatable-crud-demo{
            font-size:0.7rem;
        }

        #root > div > form > div.datatable-crud-demo > div.card > div.p-datatable.p-component > div.p-datatable-header > div > h5,
        #root > div > form > div.datatable-crud-demo > div.card > div.p-datatable.p-component > div.p-datatable-wrapper > table > thead > tr > th > span.p-column-title,
        #root > div > form > div.datatable-crud-demo > div.card > div.p-datatable.p-component > div.p-paginator.p-component.p-paginator-bottom > span.p-paginator-pages > button,
        #root > div > form > div.datatable-crud-demo > div.card > div.p-datatable.p-component > div.p-paginator.p-component.p-paginator-bottom > span.p-paginator-current,
        #pr_id_2 > span,
        #root > div > form > div.datatable-crud-demo > div.card > div.p-toolbar.p-component.p-mb-4 > div.p-toolbar-group-left > button > span.p-button-label.p-c
        {
            font-size:0.8rem;
        }
    
    /*Estilos específicos do Formulário*/
    .container_msg_chcklist_veiculo{
        text-align: center;
    }

    label.form-label.txt_alerta{
        color:red;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }

    .icone_adicionar{
        color:#FFDA53;
        font-size: 1.5rem;
        margin-top:2.4rem;
    }
    /*Fim dos Estilos específicos do Formulário*/

    /*Estilos da Grid de Paradas*/
    .datatable-crud-demo .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .datatable-crud-demo .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    
    .datatable-crud-demo .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }
    
    .datatable-crud-demo .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*Fim dos Estilos da Grid de Paradas*/