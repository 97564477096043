.disabled-create-button {
    width: 40px;
    height: 40px;
    background: #ffda53 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    text-decoration: 'none';
    cursor: default;
  }

.create-button {
    width: 40px;
    height: 40px;
    background: #ffda53 0% 0% no-repeat padding-box;
    text-decoration: 'none';
    border-radius: 5px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: 'none';
  }

  .create-button:hover {
    filter: brightness(96%);
  
  }