.resumo {
    display: flexbox;
    background: #FAFAFA;
    border-radius: 5px;
    padding: 15px;
    font-weight: 600;
    color: #5a5a5a;
    border: 1px solid rgb(211, 211, 211);
}

#row {
    display: flex;
}

.resumo p {
    margin-right: 35px;
    margin-top: -15px;
}