#table-cadastro-sat {
  display: block;
  height: 450px;
  overflow-y: scroll;
}

#table-cadastro-sat td {
  width: 175px;
}

#empresaTitle{
  width: 100%;
}

.containerDrop{
  display: flex;
}

.cancelar {
  background-color: #ffffff;
}