.return-btn {
  position: absolute;
  top: 20px;
  right: 50px;
  margin-bottom: 20px;
  width: 80px;
}

/*
--------------------
.mapa {
  height: 50%;
}

.porcentagem-div {
  height: 15px;
}


.progresso-text {
  align-self: center;
  margin-right: 10px;
  font-size: 16px;
  width: 50px;
}

.collapse {
  background-color: #e8faff;
}

#navThead {
  position: fixed;
  display: flex;
  z-index: 10;
  background-color: #ffffff;
  width: 81%;
  top: 0;
  padding: 1vh 0vh;
}

#navThead div {
  padding: 2vh;
  background-color: #ffffff;
  margin: 0 auto;
  color: #A4A4A4;
  font-size: 14px;
  justify-content:center;
  align-items:center;
  display: flex;
  font-weight: 700;
}

#navThead2 {
  display: none;
}
----------------------------------
*/

/* table td {
  width: 100px !important;
} */

/* .navMapa {
  padding: 15px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 0;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 15px;
  min-height: 95vh;
  flex: 1;
  position: absolute;
  flex-wrap: wrap;
} */