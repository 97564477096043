html{
    background-color: #f2f2f2ff;    
}
div#root{
    background-color: #f2f2f2ff;
}

div.mt-5.container-fluid{
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-color: #DEDEDE;
    border-radius: 10px;
    background-color: #FFFFFF;  
    padding-bottom: 25px;    
}

.rotas td {
    border-top: none !important;
    border-bottom: none !important;
}

span.navbar-brand{
    color: #333333;
    font-size: 28px
}


button.bg-dark.btn.btn-primary{
    margin-left: auto;
    margin-right: 10px;
    background-color:#FFDA53 !important;
    border-color: #FFDA53;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}


button.btn.btn-primary{
    margin-left: auto;
    margin-right: 10px;
    background-color:#FFDA53;
    border-color: #FFDA53;
    width: 183px;
    height: 50px;
    border-radius: 5px;
}

th{
   font-size: 14px;
   color: #A4A4A4; 
}

td{
    font-size: 16px;
}

tbody{
  font-size: 16px;
  color: #333333; 
}

i.pi.pi-caret-right.mt-2::before{
    color: #FFDA53;
}

h6.subtitulo{
    margin-top: 15px
}