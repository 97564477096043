.table {
  min-height: initial !important;
}


.titleLine {
    text-align: left;
}

.lineGridTipoComunicacao2 {
    color: #333333;
    font-family: 'Open Sans';
    border-bottom: 2px solid #dedede;
    background-color: #fff;
    box-sizing: border-box;
    padding: 3px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.lineGridTipoComunicacao2 input {
    border: none;
    outline: none;
    margin: 5px;
    margin-right: 20px;
    width: 45%;
    background-color: #fff;
    border-radius: 5px;
}

#cancel{
    font-size: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content:center;
    margin-right: 10px;
}
