.p-dropdown {
  width: 225px;
  margin-right: 20px;

}

.header-associar-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.header-associar-field {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
}

.input-number-associar {
  margin-right: 20px;
}

.icons-container-associar {
  text-align: center;
  vertical-align: middle;
}

.associar-modal-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.p-chkbox-icon {
  background-color: red;
  color: red;
}

.button-field {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.botao-associar {
  align-self: flex-end;
}
