.alertas {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

#gridExpansivel {
    background-color: #FFDA53;
    padding: 10px;
}

#link {
    cursor: pointer;
    text-decoration: underline;
}

.filtros {
    display: flex;

    align-items: center;
}

/* .expand div {
    height: 100%;
    width: 100%;
} */
.expand {
    background-color: #ffffff;
    border: 1px solid #dedede;
    font-size: 14px;
    align-items: center;
    transition: width 0.2s linear;
}

.containerProgresso {
    padding: 25px;

}

.input-grid {
    border: none;
    outline: none;
    margin: 5px;
    margin-right: 20px;
    width: 90%;
    background-color: #fff;
    border-radius: 5px;
}

.slider-demo .p-slider-horizontal, .slider-demo .p-inputtext {
    width: 14rem;
}

#range {
    border: 1px solid #dedede;
    padding: 16px;
    font-size: 10px;
    border-radius: 4px;
}

#km {
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 7px;
    /* max-width: 150px; */
}

#lsc tr {
    text-align: center;
    vertical-align: 'middle' !important;
}

#lsc #esq {
    text-align: left;
}

#lsc td {
    font-size: 12px;
}

#buttons {
    width: 70px;
    height: 40px;
    margin: 0px;
    color: black;
    font-size: 9px;
    font-weight: 600;
    text-align: center;
    background: #FFDA53;
    border: 1px solid #FFDA53;
}

#button-treatment {
  width: 70px;
  height: 40px;
  margin: 0px;
  color: black;
  font-size: 9px;
  font-weight: 600;
  text-align: center;
  background: #FFDA53;
  border: 1px solid #FFDA53;

  display: flex;
  align-items: center;
  justify-content: center;
}

#button-treatment .p-button-label {
  -ms-flex: 1 1 auto;
  flex: none;
}

#buttonConcluir {
    width: 70px;
    text-align: center;
    height: 40px;
    background: #64A338;
    border: 1px solid #64A338;
    margin: 0px;
    font-size: 9px;
    font-weight: 600;
}
