.group-swicth{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cadaster-table {
  margin-left:50px;
    height: 137vh;
    width: 74%;
    background-color: #fff;

  }
.required:after {
    content:" *"; 
    color: red;
  }

  .cadaster-table h2 {
    font-size: 42px;
    color:#000;
    padding: 10px;
  }
  .form{
    color: black;
    padding: 10px;
  }
  
/* .btn-cancel{
  width: 175px;
height: 50px;
border: 2px solid var(--unnamed-color-0b141f);
border: 2px solid #0B141F;
border-radius: 5px;
opacity: 1;
} */

.info-obrigatoria{
  color:#F33D3D;
  font-size:0.7rem;
  margin-bottom: 20px;
}