.row-container-modal {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.row-modal {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
}


