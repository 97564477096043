#modeloVeiculo {
  width: 450px;
}

#idVeiculo, #dataInicio {
  width: 300px;
}

#pontoOrigemContainer {
  width: 450px;
}

#dataInicio {
  margin-right: 20px;
}
#container {
  padding-right: 50px;
}
.table-container-agendar-viagens{
  margin-left: 70px;
  margin-right: 70px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid #cfcfcf;
}

.btn-create-agendar-viagens {
  width: 30px;
  height: 30px;
  background: #ffda53 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#numRemessa {
  width: 450px;
}

#row-index-header {
  width: 50px;
}
