.container-buttons{
  margin-top:1.5rem;
}

   .container-buttons.form-row > .form-group.col-md-2 {
      text-align: right;
      margin-right: 2rem;
  }

  .container-buttons.form-row > .form-group.col-md-2 > .btn-cancelar.btn.btn-primary{
      width: 175px;
      height: 50px;
      border: 2px solid var(--unnamed-color-0b141f);
      border: 2px solid #0B141F;
      background: #FFF 0% 0% no-repeat padding-box;
      color: #0B141F;
      border-radius: 5px;
      opacity: 1;
      font-weight: 400;
  }

   .container-buttons.form-row > .form-group.col-md-2 > .btn-enviar{
      width: 183px;
      height: 50px;
      background: #FFDA53 0% 0% no-repeat padding-box;
      border: 2px solid #FFDA53;
      color: #0B141F;
      border-radius: 5px;
      opacity: 1;
      font-weight: 400;
  }


.task-header{
    margin-top:10px;
    width: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.task-header h2{
    margin-left: 100;
}
.detail-table {
  margin-left:20px;
    height: 150vh;
    width: 150%;
    background-color: #fff;

  }

  .detail-table h2 {
    font-size: 42px;
    color:#000;
    padding: 10px;
  }
  .form{
width: 120%;
    color: white;
    padding: 10px;
  } 
   /* .btn-edit-back{
      right: 1px;
      position: absolute;
    padding: 5px;
    margin-top: 17px;
  }

   */





